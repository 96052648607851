import React from "react";
import styled from "styled-components";
import profileImage from "./profile.jpg.JPG";
import postImage from "./post.jpg.JPG";

// Verify image paths
console.log("Profile Image Path:", profileImage);
console.log("Post Image Path:", postImage);

// Styled Components for CSS
const AppContainer = styled.div`
  font-family: Arial, sans-serif;
  background-color: #fafafa;
  color: #333;
  margin: 0;
`;

const Navbar = styled.div`
  background-color: #fff;
  padding: 10px 20px;
  border-bottom: 1px solid #dbdbdb;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 100;
`;

const NavbarTitle = styled.h1`
  font-size: 24px;
  color: #333;
`;

const ProfileHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  background-color: #fff;
  border-bottom: 1px solid #dbdbdb;
`;

const ProfilePic = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 20px;
`;

const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;

  h2 {
    font-size: 20px;
    margin: 0;
  }

  p {
    margin: 5px 0 0;
    color: #777;
  }
`;

const PostContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const PostImage = styled.img`
  width: 100%;
  max-width: 600px;
  border-radius: 10px;
`;

const PostCaption = styled.h3`
  font-size: 16px;
  margin-top: 10px;
  color: #333;
`;

// Main App Component
function App() {
  return (
    <AppContainer>
      <Navbar>
        <NavbarTitle>About Me</NavbarTitle>
      </Navbar>

      {/* Profile Header */}
      <ProfileHeader>
        <ProfilePic src={profileImage} alt="Profile" />
        <ProfileInfo>
          <h2>charlieee03</h2>
          <p>Welcome to my profile!</p>
        </ProfileInfo>
      </ProfileHeader>

      {/* Post Section */}
      <PostContainer>
        <PostImage src={postImage} alt="Post" />
        <PostCaption>Posted by charlieee03</PostCaption>
      </PostContainer>
    </AppContainer>
  );
}

export default App;
